import { get } from "lodash";
import serverCall from "../serverCall";

const signUp = (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  return serverCall
    .post(`/auth/login?email=${email}&password=${password}`)
    .then((response) => {
      const data = get(response, "data", null);
      if (data) {
        const user = data.data.email;
        const token = data.token;
        const userAndToken = {
          user,
          token,
        };
        localStorage.setItem("userProfile", JSON.stringify(userAndToken));
      }
      return data;
    });
};

const AuthenticationService = {
  signUp,
};

export default AuthenticationService;
