import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setPageTitle } from "../../store/themeConfigSlice";
import IconMail from "../../components/Icon/IconMail";
import IconLockDots from "../../components/Icon/IconLockDots";
import { useFormik } from "formik";
import * as yup from "yup";
import { login } from "../../store/slice/auth";
import Swal from "sweetalert2";
import BgImage from "../../assets/images/auth/bg-gradient.png";
import Object1 from "../../assets/images/auth/coming-soon-object1.png";
import Object2 from "../../assets/images/auth/coming-soon-object2.png";
import Object3 from "../../assets/images/auth/coming-soon-object3.png";
import Object0 from "../../assets/images/auth/polygon-object.svg";

const LoginBoxed = () => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(setPageTitle("Login Boxed"));
  // });
  const navigate = useNavigate();

  const submitForm = async (values, action) => {
    try {
      const { email, password } = values;
      await dispatch(login({ email, password })).unwrap();
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      const toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        customClass: {
          popup: `color-${"success"}`,
        },
      });
      toast.fire({
        title: "User login successfully",
      });
      navigate("/event");
      action.resetForm();
      window.location.reload();
    } catch (error) {
      action.resetForm();
      const toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        customClass: {
          popup: `color-${"danger"}`,
        },
      });
      toast.fire({
        title: error,
      });
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const schema = yup.object().shape({
    email: yup.string().email().required("Enter your email"),
    password: yup.string().required("Enter your password"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: submitForm,
  });

  return (
    <div>
      <div className="absolute inset-0">
        <img src={BgImage} alt="image" className="h-full w-full object-cover" />
      </div>

      <div className="relative flex min-h-screen items-center justify-center  bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
        <img
          src={Object1}
          alt="image"
          className="absolute left-0 top-1/2 h-full max-h-[893px] -translate-y-1/2"
        />
        <img
          src={Object2}
          alt="image"
          className="absolute left-24 top-0 h-40 md:left-[30%]"
        />
        <img
          src={Object3}
          alt="image"
          className="absolute right-0 top-0 h-[300px]"
        />
        <img
          src={Object0}
          alt="image"
          className="absolute bottom-0 end-[28%]"
        />
        <div className="relative w-full max-w-[870px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]">
          <div className="relative flex flex-col justify-center rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 px-6 lg:min-h-[758px] py-20">
            <div className="mx-auto w-full max-w-[440px]">
              <div className="mb-10">
                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">
                  Sign in
                </h1>
                <p className="text-base font-bold leading-normal text-white-dark">
                  Enter your email and password to login
                </p>
              </div>
              <form
                className="space-y-5 dark:text-white"
                onSubmit={formik.handleSubmit}
              >
                <div>
                  <label htmlFor="Email">Email</label>
                  <div className="relative text-white-dark">
                    <input
                      id="Email"
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Enter Email"
                      className="form-input ps-10 placeholder:text-white-dark"
                    />
                    <span className="absolute start-4 top-1/2 -translate-y-1/2">
                      <IconMail fill={true} />
                    </span>
                  </div>
                  {formik.errors.email && formik.touched.email && (
                    <p className="text-red-500 ms-2 text-[14px]">
                      {formik.errors.email}
                    </p>
                  )}
                </div>
                <div>
                  <label htmlFor="Password">Password</label>
                  <div className="relative text-white-dark">
                    <input
                      id="Password"
                      name="password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      placeholder="Enter Password"
                      className="form-input ps-10 placeholder:text-white-dark"
                    />
                    <span className="absolute start-4 top-1/2 -translate-y-1/2">
                      <IconLockDots fill={true} />
                    </span>
                  </div>
                  {formik.errors.password && formik.touched.password && (
                    <p className="text-red-500 ms-2 text-[14px]">
                      {formik.errors.password}
                    </p>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-gradient !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBoxed;
