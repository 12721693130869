import serverCall from "../serverCall";
const categoryData = async () => {
  const response = await serverCall.get(`/events/getAllCategory`);
  return response;
};
const refreshCategory = async (ids) => {
  const response = await serverCall.post(`/events/addCategoryData`, ids);
  return response;
};
const categoryService = { categoryData, refreshCategory };
export default categoryService;
