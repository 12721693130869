import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleRTL,
  toggleTheme,
  toggleMenu,
  toggleLayout,
  toggleAnimation,
  toggleNavbar,
  toggleSemidark,
} from "./store/themeConfigSlice";
import store from "./store";
import { MantineProvider } from "@mantine/core";
import Routes from "./router/routes";
import { BrowserRouter } from "react-router-dom";

function App({ children }) {
  const themeConfig = useSelector((state) => state.themeConfig);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleTheme(localStorage.getItem("theme") || themeConfig.theme));
    dispatch(toggleMenu(localStorage.getItem("menu") || themeConfig.menu));
    dispatch(
      toggleLayout(localStorage.getItem("layout") || themeConfig.layout)
    );
    dispatch(
      toggleRTL(localStorage.getItem("rtlClass") || themeConfig.rtlClass)
    );
    dispatch(
      toggleAnimation(
        localStorage.getItem("animation") || themeConfig.animation
      )
    );
    dispatch(
      toggleNavbar(localStorage.getItem("navbar") || themeConfig.navbar)
    );
    // dispatch(
    //   toggleLocale(localStorage.getItem("i18nextLng") || themeConfig.locale)
    // );
    dispatch(
      toggleSemidark(localStorage.getItem("semidark") || themeConfig.semidark)
    );
  }, [
    dispatch,
    themeConfig.theme,
    themeConfig.menu,
    themeConfig.layout,
    themeConfig.rtlClass,
    themeConfig.animation,
    themeConfig.navbar,
    // themeConfig.locale,
    themeConfig.semidark,
  ]);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const isLoggedin = isLoggedIn || !!localStorage.getItem("token");
  return (
    <>
      <MantineProvider>
        <div
          className={`${
            (store.getState().themeConfig.sidebar && "toggle-sidebar") || ""
          } ${themeConfig.menu} ${themeConfig.layout} ${
            themeConfig.rtlClass
          } main-section antialiased relative font-nunito text-sm font-normal`}
        >
          <BrowserRouter basename={process.env.REACT_APP_HOME_PAGE}>
            {/* <React.Suspense> */}
            <Routes isLoggedIn={isLoggedin} />
            {/* </React.Suspense> */}
          </BrowserRouter>
        </div>
      </MantineProvider>
    </>
  );
}
export default App;
