import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState, useCallback } from "react";
import sortBy from "lodash/sortBy";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { setPageTitle } from "../../store/themeConfigSlice";
import IconBell from "../../components/Icon/IconBell";
import IconStar from "../../components/Icon/IconStar";
import CustomPagination from "../../components/Layouts/CustomPagination";
import IconRefresh from "../../components/Icon/IconRefresh";
import SelectAll from "react-select";
import Dropdown from "../../components/Dropdown";
import IconCaretDown from "../../components/Icon/IconCaretDown";
import eventService from "../../service/event.service";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import StatusDropdown from "../../components/StatusDropdown";
import moment from "moment";
import DefaultLayout from "../../components/Layouts/DefaultLayout";

const Basic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTire, setSelectedTire] = useState(null);
  const [selectedTireUpdate, setSelectedTireUpdate] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [tireData, setTireData] = useState([]);
  const [page, setPage] = useState(0);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [recordsData, setRecordsData] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(null);
  const initialCheckedState = rowData.map(() => ({
    check: false,
    event_id: null,
  }));
  const [checked, setChecked] = useState(initialCheckedState);

  const isRtl =
    useSelector((state) => state.themeConfig.rtlClass) === "rtl" ? true : false;

  const statusOptions = [
    { value: "allevent", label: "All" },
    { value: "upcomingevent", label: "Upcoming" },
    { value: "pastevent", label: "Past" },
  ];

  const optionsCategory = async () => {
    try {
      const response = await eventService.categoryOptions();
      setCategoryData(response?.data?.data);
    } catch (e) {
      console.error("e", e);
    }
  };
  const optionsTire = async () => {
    try {
      const response = await eventService.tireOptions();
      setTireData(response?.data?.data);
    } catch (e) {
      console.log("e", e);
    }
  };
  const categoryOptions = categoryData.map((item) => ({
    value: item.cat_id,
    label: item.cat_name,
  }));
  const tireOption = tireData.map((item) => ({
    value: item.tier_id,
    label: item.name,
  }));
  console.log("selectedCategoryselectedCategory", selectedCategory);
  useEffect(() => {
    setSelectedCategory(categoryOptions[0]);
    setSelectedTire(tireOption[0]);
  }, [categoryData, tireData]);

  const getAllEvent = async () => {
    try {
      setLoading(true);
      console.log("categoryOptionsbnmmm", selectedCategory.value);
      const data = {
        page: page,
        size: pageSize,
        tier: selectedTire.value,
        eventStartDate: "",
        eventEndDate: "",
        categoryId: selectedCategory !== null && [selectedCategory.value],
      };
      const response = await eventService.allEvent(
        data,
        selectedStatus || "upcomingevent"

        // page,
        // pageSize,
        // selectedCategory !== null && selectedCategory.value,
        // selectedStatus || "upcomingevent"
      );
      setLoading(false);
      console.log("fdsfdsfdsfdg", response);
      const result = response?.data?.data;
      setRowData(result);
      setRecordsData(response?.data?.total);
    } catch (e) {
      setLoading(false);
      console.error("e", e);
    }
  };

  useEffect(() => {
    getAllEvent();
  }, [page, pageSize, selectedTire, selectedCategory, selectedStatus]);

  useEffect(() => {
    optionsCategory();
    optionsTire();
  }, []);

  const handleStatusChange = (option) => {
    setSelectedStatus(option);
  };
  const handleCategoryChange = (option) => {
    setLoading(true);
    setSelectedCategory(option);
  };
  const handleTireChange = (option) => {
    setLoading(true);
    setSelectedTire(option);
  };
  const handleTireUpdateChange = async (option) => {
    console.log("optionssss", option?.value);
    try {
      const event_ids = checked
        .filter((item) => item.check === true)
        .map((item) => item.event_id);
      const response = await eventService.updateData({
        event_id: event_ids,
        tier_id: option?.value,
      });
      if (event_ids.length !== 0) {
        const toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          showCloseButton: true,
          customClass: {
            popup: `color-${"success"}`,
          },
        });
        toast.fire({
          title: "Data Added successfully",
        });
      }
      setCheckAll(null);
      setChecked(initialCheckedState);
      console.log("responseeeeee", response);
    } catch (e) {
      console.log("e", e);
      const toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        customClass: {
          popup: `color-${"danger"}`,
        },
      });
      toast.fire({
        title: "Something went wrong",
      });
    }

    // setLoading(true);
    // setSelectedTireUpdate(option);
  };
  // useEffect(() => {
  //   dispatch(setPageTitle("Advanced Table"));
  // });

  // const [sortStatus, setSortStatus] = useState();
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "id",
    direction: "asc",
  });

  const handleChange = (type, page) => {
    const total = recordsData / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page < newPage - 1) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page > 0) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      setPage(0);
    } else if (type === "Last") {
      setPage(newPage - 1);
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  useEffect(() => {
    setPage(0);
  }, [pageSize]);

  const handleRefreshData = async () => {
    try {
      const event_ids = checked
        .filter((item) => item.check === true)
        .map((item) => item.event_id);
      await eventService.RefreshData({ event_id: event_ids });
      if (event_ids.length !== 0) {
        const toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          showCloseButton: true,
          customClass: {
            popup: `color-${"success"}`,
          },
        });
        toast.fire({
          title: "Data Added successfully",
        });
      }
      // const toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 3000,
      //   showCloseButton: true,
      //   customClass: {
      //     popup: `color-${"success"}`,
      //   },
      // });
      // toast.fire({
      //   title: "Data Added successfully",
      // });
      setCheckAll(null);
      setChecked(initialCheckedState);
    } catch (error) {
      console.error(error);
      const toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        customClass: {
          popup: `color-${"danger"}`,
        },
      });
      toast.fire({
        title: "Something went wrong",
      });
    }
  };
  const handleReset = useCallback(() => {
    setSelectedCategory(categoryOptions[0]);
    setSelectedStatus("");
    setSelectedTire(tireOption[0]);
    // setSelectedCatOptionValue("");
    // setSelectedCatOption("");
    getAllEvent();
  }, [selectedCategory, selectedTire]);
  useEffect(() => {
    setChecked(
      rowData.map((item) => ({ check: false, event_id: item.event_id }))
    );
  }, [rowData]);

  const handleSingleData = (check, index, event_id) => {
    setChecked((prev) => {
      const updatedChecked = [...prev];
      updatedChecked[index] = { check, event_id };
      return updatedChecked;
    });
  };

  const handleSelectAll = (e) => {
    const check = e.target.checked;
    setCheckAll(check);
    setChecked(rowData.map((item) => ({ check, event_id: item.event_id })));
  };
  console.log(
    "checked",
    checked.map((item) => item.check !== true)
  );
  return (
    // <DefaultLayout>
    <div>
      <div className="panel mt-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex gap-5 items-center justify-center">
            <h5 className="font-semibold text-lg dark:text-white-light">
              Events
            </h5>
            <div className="dropdown">
              <Dropdown
                placement={`${isRtl ? "bottom-start" : "bottom-end"}`}
                btnClassName="btn btn-outline-primary dropdown-toggle shadow-none"
                button={
                  <>
                    {selectedCategory
                      ? selectedCategory.label
                      : categoryData[0]?.cat_name}
                    <span>
                      <IconCaretDown className="ltr:ml-1 rtl:mr-1 inline-block" />
                    </span>
                  </>
                }
                options={categoryOptions}
                onOptionSelect={handleCategoryChange}
              ></Dropdown>
            </div>
            <div className="dropdown">
              <StatusDropdown
                placement={`${isRtl ? "bottom-start" : "bottom-end"}`}
                btnClassName="btn btn-outline-primary dropdown-toggle shadow-none"
                button={
                  <>
                    {selectedTire ? selectedTire.label : tireData[0]?.name}
                    <span>
                      <IconCaretDown className="ltr:ml-1 rtl:mr-1 inline-block" />
                    </span>
                  </>
                }
                options={tireOption}
                onOptionSelect={handleTireChange}
              ></StatusDropdown>
            </div>
            <div className="dropdown">
              <StatusDropdown
                placement={`${isRtl ? "bottom-start" : "bottom-end"}`}
                btnClassName="btn btn-outline-primary dropdown-toggle shadow-none"
                button={
                  <>
                    {selectedStatus ? selectedStatus.label : "Upcoming"}
                    <span>
                      <IconCaretDown className="ltr:ml-1 rtl:mr-1 inline-block" />
                    </span>
                  </>
                }
                options={statusOptions}
                onOptionSelect={handleStatusChange}
              ></StatusDropdown>
            </div>
            <button
              type="button"
              onClick={handleReset}
              className="btn btn-primary flex items-center gap-3 shadow-none"
            >
              Reset
            </button>
          </div>
          {/* <button
            type="button"
            disabled={checked.length === 0}
            onClick={handleRefreshData}
            className="btn btn-primary flex items-center gap-3 shadow-none"
          >
            <IconRefresh /> Refresh
          </button> */}
          <div className="dropdown">
            <StatusDropdown
              disabled={
                checked.filter((item) => item.check === true).length === 0
                  ? true
                  : false
              }
              placement={`${isRtl ? "bottom-start" : "bottom-end"}`}
              btnClassName="btn btn-outline-primary dropdown-toggle shadow-none"
              button={
                <>
                  Update
                  {/* {selectedTireUpdate
                    ? selectedTireUpdate.label
                    : tireData[0]?.name} */}
                  <span>
                    <IconCaretDown className="ltr:ml-1 rtl:mr-1 inline-block" />
                  </span>
                </>
              }
              options={tireOption}
              onOptionSelect={handleTireUpdateChange}
            ></StatusDropdown>
          </div>
        </div>
        <div className="datatables Advanced-pagination">
          {/* {loading ? (
            <p>loading.........</p>
          ) : ( */}
          <>
            <DataTable
              onProgress={"loadingg................."}
              noRecordsText="No results match your search query"
              highlightOnHover
              className="whitespace-nowrap table-hover Advanced-pagination"
              records={rowData}
              columns={[
                {
                  title: (
                    <div forHtml="checkbox">
                      <label className="flex cursor-pointer items-center">
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={checkAll}
                          className="form-checkbox bg-white dark:bg-black"
                        />
                      </label>
                    </div>
                  ),
                  render: ({ event_id }, index) => (
                    <div id="checkbox">
                      <label className="flex cursor-pointer items-center">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleSingleData(e.target.checked, index, event_id)
                          }
                          checked={checked[index]?.check || false}
                          className="form-checkbox bg-white dark:bg-black"
                        />
                      </label>
                    </div>
                  ),
                },
                {
                  // accessor: "event_title",
                  title: "Name",
                  // sortable: true,
                  render: ({ event_title }) => (
                    <div className="font-semibold">{event_title}</div>
                  ),
                },
                {
                  // accessor: "category",
                  title: "Category",
                  // sortable: true,
                  render: ({ event_categories, is_active }) => (
                    <div
                    // style={{ color: is_active === true ? "green" : "red" }}
                    >
                      {event_categories.map((item) => item.cat_name).join(", ")}
                    </div>
                  ),
                },
                {
                  // accessor: "specification",
                  title: "Specifications",
                  // sortable: true,
                  render: ({ event_specifications }) => (
                    <div>
                      {event_specifications
                        .map((item) => item.cat_name)
                        .join(", ")}
                    </div>
                  ),
                },
                {
                  // accessor: "category",
                  title: "Date",
                  // sortable: true,
                  render: ({ event_date }) => (
                    <div>{moment(event_date).format("DD-MM-YYYY")}</div>
                  ),
                },
                {
                  // accessor: "country",
                  title: "Country",
                  // sortable: true,
                  render: ({ event_country, event_flag }) => (
                    <div className="flex items-center gap-2">
                      <img
                        width="24"
                        src={event_flag}
                        className="max-w-none"
                        alt="flag"
                      />
                      <div>{event_country}</div>
                    </div>
                  ),
                },
                {
                  // accessor: "action",
                  title: "Action",
                  // sortable: true,
                  render: ({ event_id, event_title }) => (
                    <button
                      type="button"
                      className="btn btn-primary flex items-center gap-3 shadow-none"
                      onClick={() =>
                        navigate(`/event/${event_id}`, {
                          state: event_title,
                        })
                      }
                    >
                      Athlete
                    </button>
                  ),
                },
              ]}
              totalRecords={recordsData}
              recordsPerPage={pageSize}
              // page={page}
              onPageChange={(p) => setPage(p - 1)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              minHeight={200}
              loading={loading}
              fetching={loading}
              paginationText={({ from, to, totalRecords }) =>
                `Showing  ${from} to ${to} of ${totalRecords} entries`
              }
            />
            {rowData?.length !== 0 && (
              <CustomPagination
                total={recordsData}
                page={page}
                handleChange={handleChange}
                dataPerPage={pageSize}
                handleDataPerPage={handleDataPerPage}
                PAGE_SIZES={PAGE_SIZES}
              />
            )}
          </>
          {/* )} */}
        </div>
      </div>
    </div>
    // </DefaultLayout>
  );
};

export default Basic;
