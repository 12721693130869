import { DataTable } from "mantine-datatable";
import React, { useState } from "react";
import CustomPagination from "../../components/Layouts/CustomPagination";
import eventService from "../../service/event.service";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import Breadcrump from "./Breadcrump";

const AthleteData = () => {
  const [rowData, setRowData] = useState([]);
  const [paginationRowData, setPaginationRowData] = useState([]);
  const [recordsData, setRecordsData] = useState("");
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [loading, setLoading] = useState(false);
  const { prog_id } = useParams();
  const location = useLocation();
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "id",
    direction: "asc",
  });
  const { event_id, prog_name, eventName } = location?.state;

  const breadcrumbItems = [
    { eventName: eventName, path: "/" },
    { eventName: prog_name, path: `/${event_id}`, state: eventName },
    { eventName: "Athletes", path: "" },
  ];

  const getAtheleteList = async () => {
    try {
      setLoading(true);
      const response = await eventService.athleteData(event_id, prog_id);
      const result = response?.data?.data;
      setPaginationRowData(result);
      setRecordsData(response.data.total);
      setLoading(false);
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleChange = (type, page) => {
    const total = recordsData / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };
  useEffect(() => {
    setPage(1);
  }, [pageSize]);
  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRowData(paginationRowData && [...paginationRowData.slice(from, to)]);
  }, [page, pageSize, paginationRowData]);
  useEffect(() => {
    getAtheleteList();
  }, []);
  return (
    <>
      <div>
        <div className="panel mt-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex gap-5 items-center justify-center">
              <h5 className="font-semibold text-lg dark:text-white-light cursor-pointer">
                {/* {`${eventName} > ${prog_name} > Athletes`} */}
                <Breadcrump items={breadcrumbItems} mixData="athlete" />
              </h5>
            </div>
          </div>
          <div className="datatables Advanced-pagination">
            <DataTable
              noRecordsText="No results match your search query"
              highlightOnHover
              className="whitespace-nowrap table-hover Advanced-pagination"
              records={rowData}
              columns={[
                {
                  // accessor: "athlete_first",
                  title: "First Name",
                  // sortable: true,
                  render: ({ athlete_first }) => <div>{athlete_first}</div>,
                },
                {
                  // accessor: "athlete_last",
                  title: "Last Name",
                  // sortable: true,
                  render: ({ athlete_last }) => <div>{athlete_last}</div>,
                },
                {
                  // accessor: "athlete_yob",
                  title: "YOB",
                  // sortable: true,
                  render: ({ athlete_yob }) => <div>{athlete_yob}</div>,
                },
                {
                  // accessor: "country",
                  title: "Country",
                  // sortable: true,
                  render: ({ athlete_country_name, athlete_flag }) => (
                    <div className="flex items-center gap-2">
                      <img
                        width="24"
                        src={athlete_flag}
                        className="max-w-none"
                        alt="flag"
                      />
                      <div>{athlete_country_name}</div>
                    </div>
                  ),
                },
              ]}
              totalRecords={recordsData}
              recordsPerPage={pageSize}
              // page={page}
              onPageChange={(p) => setPage(p)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              minHeight={200}
              loading={loading}
              fetching={loading}
              paginationText={({ from, to, totalRecords }) =>
                `Showing  ${from} to ${to} of ${totalRecords} entries`
              }
            />
            {rowData?.length !== 0 && (
              <CustomPagination
                total={recordsData}
                page={page}
                handleChange={handleChange}
                dataPerPage={pageSize}
                handleDataPerPage={handleDataPerPage}
                PAGE_SIZES={PAGE_SIZES}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AthleteData;
