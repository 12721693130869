import moment from "moment/moment";
import serverCall from "../serverCall";

const categoryOptions = async () => {
  const response = await serverCall.get(`/events/category`);
  return response;
};
const tireOptions = async () => {
  const response = await serverCall.get(`/events/getAllTier`);
  return response;
};
const RefreshData = async (ids) => {
  const response = await serverCall.post(`events/addData`, ids);
  return response;
};
const allEvent = async (data, option) => {
  const crDate = moment().format("YYYY-MM-DD");
  if (
    (option !== null && option.value === "upcomingevent") ||
    option === "upcomingevent"
  ) {
    data.eventStartDate = crDate;
  }
  if (option !== null && option.value === "pastevent") {
    data.eventEndDate = crDate;
  }
  console.log(data, "==== data ========");
  const response = await serverCall.post(`/events/getAll`, data);
  return response;
};
// const allEvent = async (page, limit, categoryid, option) => {
//   const crDate = moment().format("YYYY-MM-DD");
//   let response = `/events?page=${page}&perPage=${limit}`;

//   if (categoryid) {
//     response += `&categoryId=${categoryid}`;
//   }
//   if (
//     (option !== null && option.value === "upcomingevent") ||
//     option === "upcomingevent"
//   ) {
//     response += `&eventStartDate=${crDate}`;
//   }
//   // if (option !== null && option.value === "allevent") {
//   //   response += `&eventStartDate=${crDate}&eventEndDate=${crDate}`;
//   // }
//   if (option !== null && option.value === "pastevent") {
//     response += `&eventEndDate=${crDate}`;
//   }
//   return await serverCall.get(response);
// };
const manWomandata = async (eventId)=>{
  const response = await serverCall.get(`/events/getAthlete?eventId=${eventId}`)
  return response
}
const startListData = async (id) => {
  const response = await serverCall.get(`/events/program?id=${id}`);
  return response;
};
const athleteData = async (eventId, programId) => {
  const response = await serverCall.get(
    `/events/getAllAthlete?eventId=${eventId}&programId=${programId}`
  );
  return response;
};

const updateData = async (data) => {
  const response = await serverCall.patch(`/events/updateEventData`, data);
  return response;
};
const eventService = {
  categoryOptions,
  allEvent,
  manWomandata,
  tireOptions,
  startListData,
  RefreshData,
  athleteData,
  updateData,
};
export default eventService;
