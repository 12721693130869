import React from "react";
import { useNavigate } from "react-router-dom";

const Breadcrump = ({ items, mainItem, mixData }) => {

  const navigate = useNavigate();
  const handleNavigate = (item, eventName) => {
    if (item?.path) {
      navigate(`${item.path}`, { state: eventName });
    }
  };
  const handleMainItemNavigate = (item) => {
    if (item?.path) {
      navigate(`${item.path}`);
    }
  };
  return (
    <nav className="text-sm font-semibold">
      <ol className="flex space-x-2">
        {mixData === "athlete"
          ? items.map((item, index) => (
              <li
                key={index}
                className="flex items-center"
                onClick={() => handleNavigate(item, item.state)}
              >
                <span className="text-gray-600 hover:underline">
                  {item?.eventName}
                </span>
                {index < items.length - 1 && (
                  <span className="mx-2 text-gray-400"> > </span>
                )}
              </li>
            ))
          : mainItem?.map((item, index) => (
              <li
                key={index}
                className="flex items-center"
                onClick={() => handleMainItemNavigate(item)}
              >
                <span className="text-gray-600 hover:underline">
                  {item?.eventName}
                </span>
                {index < mainItem.length - 1 && (
                  <span className="mx-2 text-gray-400"> > </span>
                )}
              </li>
            ))}
      </ol>
    </nav>
  );
};

export default Breadcrump;
