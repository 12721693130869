import { combineReducers, configureStore } from "@reduxjs/toolkit";
import themeConfigSlice from "./themeConfigSlice";
import authReducer from "./slice/auth";

const rootReducer = combineReducers({
  themeConfig: themeConfigSlice,
  auth: authReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
