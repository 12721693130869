import React from "react";

const Footer = () => {
  return (
    <>
      <div className="dark:text-white-dark text-center p-6 bg-white shadow-[5px_0_25px_0_rgba(94,92,154,0.1)]">
        © {new Date().getFullYear()}. Triathlon fantasy All rights reserved.
      </div>
    </>
  );
};

export default Footer;
