// import React, { Suspense } from "react";
// import ReactDOM from "react-dom/client";
// // Pagination
// import "@mantine/core/styles.css";
// // Perfect Scrollbar
// import "react-perfect-scrollbar/dist/css/styles.css";
// // Tailwind css
// import "./App.css";
// import "./index.css";
// // i18n (needs to be bundled)
// // import "./i18n";
// // Router
// import { RouterProvider } from "react-router-dom";
// import router from "./router/index";
// // Redux
// import { Provider } from "react-redux";
// import store from "./store/index";
// import "@mantine/core/styles.css";
// import "@mantine/core/styles.layer.css";
// import "mantine-datatable/styles.layer.css";

// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <Suspense>
//       <Provider store={store}>
//         <RouterProvider router={router} />
//       </Provider>
//     </Suspense>
//   </React.StrictMode>
// );


import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
// Pagination
import "@mantine/core/styles.css";
// Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
// Tailwind css
import "./App.css";
import "./index.css";
// i18n (needs to be bundled)
// import "./i18n";
// Router
import { RouterProvider } from "react-router-dom";
// Redux
import { Provider } from "react-redux";
import store from "./store/index";
import "@mantine/core/styles.css";
import "@mantine/core/styles.layer.css";
import "mantine-datatable/styles.layer.css";
import App from "./App";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense>
      <Provider store={store}>
        <App />
        {/* <RouterProvider router={router} /> */}
      </Provider>
    </Suspense>
  </React.StrictMode>
);
