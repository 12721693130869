import StartListTable from "../pages/DataTables/StartListTable.js";
import AthleteData from "../pages/DataTables/AthleteData.js";
import Category from "../pages/DataTables/category/Category.js";
import { Navigate, useRoutes } from "react-router-dom";
import MatxLayout from "../pages/maxLayout";
import Advanced from "../pages/DataTables/Advanced.js";
import LoginBoxed from "../pages/Authentication/LoginBoxed.js";
import BlankLayout from "../components/Layouts/BlankLayout.js";

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/event" />,
      },
      {
        path: "/event",
        element: <Advanced />,
      },
      {
        path: "/event/:event_id",
        element: <StartListTable />,
      },
      {
        path: "/event/list/athlete/:prog_id",
        element: <AthleteData />,
      },
      {
        path: "/category",
        element: <Category />,
      },
      // {
      //   path: "/login",
      //   element: <LoginBoxed />,
      //   layout: "blank",
      // },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? <LoginBoxed /> : <RedirectionWrapper to="/event" />,
  },
  { path: "*", element: <BlankLayout /> },
];
export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
