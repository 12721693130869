import { DataTable } from "mantine-datatable";
import React, { Fragment, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomPagination from "../../components/Layouts/CustomPagination";
import eventService from "../../service/event.service";
import { useEffect } from "react";
import IconEye from "../../components/Icon/IconEye";
import Breadcrump from "./Breadcrump";
import { Tab } from "@headlessui/react";

const StartListTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { event_id } = useParams();
  const [rowData, setRowData] = useState([]);
  const [paginationRowData, setPaginationRowData] = useState([]);
  const [allData, setAlldata] = useState("");
  const [page, setPage] = useState(0);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const recordsData = paginationRowData?.length;
  const [loading, setLoading] = useState(false);
  const [tabid, setTabid] = useState(1);
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "id",
    direction: "asc",
  });
  const eventName = location?.state;

  // const breadcrumbItems = [
  //   {
  //     eventName: eventName.eventName ? eventName.eventName : eventName,
  //     path: "/",
  //   },
  //   { eventName: "Start list", path: "" },
  // ];
  const getAllList = async () => {
    try {
      setLoading(true);
      const response = await eventService.startListData(event_id);
      const result = response?.data?.data?.data;
      console.log("result?.male", result?.male);
      setPaginationRowData(result?.male);
      setLoading(false);
    } catch (e) {
      console.log("e", e);
    }
  };
  const getAllData = async () => {
    try {
      setLoading(true);
      const response = await eventService.manWomandata(event_id);
      const result = response?.data?.data;
      console.log("🚀  getAllData  result:", result);
      setAlldata(result);
      setPaginationRowData(result?.male);
      setLoading(false);
    } catch (e) {
      console.log("e", e);
    }
  };
  const handlegetBack = () => {
    navigate("/event");
  };
  useEffect(() => {
    if (tabid === 1) {
      setLoading(true);
      setPaginationRowData(allData?.male);
      setLoading(false);
    } else {
      setLoading(true);
      setPaginationRowData(allData?.female);
      setLoading(false);
    }
  }, [tabid]);
  console.log("tabidtabid", tabid);
  const handleChange = (type, page) => {
    const total = recordsData / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page < newPage - 1) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page > 0) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      setPage(0);
    } else if (type === "Last") {
      setPage(newPage - 1);
    } else {
      setPage(page);
    }
  };
  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };
  const handleTabChange = (id) => {
    setTabid(id);
    console.log("asfdf", id);
  };

  useEffect(() => {
    setPage(0);
  }, [pageSize]);
  useEffect(() => {
    const from = page * pageSize;
    const to = from + pageSize;
    setRowData(paginationRowData && [...paginationRowData?.slice(from, to)]);
  }, [page, pageSize, paginationRowData]);
  useEffect(() => {
    getAllData();
    // getAllList();
  }, [page, pageSize, event_id]);

  return (
    <>
      <div>
        <div className="panel mt-6 event-table">
          <h5 className="font-semibold text-lg text-gray-600">
            <span
              className="cursor-pointer hover:underline"
              onClick={handlegetBack}
            >
              {eventName}
            </span>
            {" > "}
            <span>Athlete</span>
          </h5>
          <div className="flex items-center justify-center mb-4">
            {/* <div className="flex gap-5 items-center justify-center"> */}
            {/* <h5 className="font-semibold text-lg dark:text-white-light cursor-pointer"> */}
            {/* {`${eventName} > Start list`} */}
            {/* <Breadcrump mainItem={breadcrumbItems} mixData="startList" /> */}
            <Tab.Group>
              <Tab.List className="mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a]">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`${
                        selected
                          ? "text-secondary !outline-none before:!w-full"
                          : ""
                      }
                                                    before:inline-block' relative -mb-[1px] flex items-center p-5 py-3 before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[1px] before:w-0 before:bg-secondary before:transition-all before:duration-700 hover:text-secondary hover:before:w-full`}
                      onClick={() => handleTabChange(1)}
                    >
                      {/* <IconHome className="ltr:mr-2 rtl:ml-2" /> */}
                      Man
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`${
                        selected
                          ? "text-secondary !outline-none before:!w-full"
                          : ""
                      }
                                                before:inline-block' relative -mb-[1px] flex items-center p-5 py-3 before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[1px] before:w-0 before:bg-secondary before:transition-all before:duration-700 hover:text-secondary hover:before:w-full`}
                      onClick={() => handleTabChange(2)}
                    >
                      {/* <IconUser className="w-5 h-5 ltr:mr-2 rtl:ml-2" /> */}
                      Woman
                    </button>
                  )}
                </Tab>
              </Tab.List>
              {/* <Tab.Panels>
              <Tab.Panel>
                <div className="datatables Advanced-pagination">
                  <DataTable
                    noRecordsText="No results match your search query"
                    highlightOnHover
                    className="whitespace-nowrap table-hover Advanced-pagination"
                    records={rowData}
                    columns={[
                      {
                        // accessor: "prog_name",
                        title: "Name",

                        // sortable: true,
                        render: ({ prog_name }) => <div>{prog_name}</div>,
                      },
                      {
                        // accessor: "prog_gender",
                        title: "Gender",
                        // sortable: true,
                        render: ({ prog_gender }) => <div>{prog_gender}</div>,
                      },
                      {
                        // accessor: "prog_distance_category",
                        title: "Category",
                        // sortable: true,
                        render: ({ prog_distance_category }) => (
                          <div>{prog_distance_category}</div>
                        ),
                      },
                      {
                        // accessor: "prog_distances",
                        title: "Distances",
                        // sortable: true,
                        render: ({ prog_distances }) => (
                          <div>
                            {prog_distances
                              .map((item) => item.segment)
                              .join(", ")}
                          </div>
                        ),
                      },
                      {
                        // accessor: "prog_date",
                        title: "Date",
                        // sortable: true,
                        render: ({ prog_date }) => <div>{prog_date}</div>,
                      },

                      {
                        // accessor: "action",
                        title: "Action",
                        // sortable: true,
                        render: ({ prog_id, event_id, prog_name }) => (
                          <button
                            type="button"
                            className="btn btn-primary flex items-center gap-3 shadow-none"
                            onClick={() =>
                              navigate(`/event/list/athlete/${prog_id}`, {
                                state: { event_id, prog_name, eventName },
                              })
                            }
                          >
                            <IconEye />
                          </button>
                        ),
                      },
                    ]}
                    totalRecords={recordsData}
                    recordsPerPage={pageSize}
                    // page={page}
                    onPageChange={(p) => setPage(p)}
                    recordsPerPageOptions={PAGE_SIZES}
                    onRecordsPerPageChange={setPageSize}
                    sortStatus={sortStatus}
                    onSortStatusChange={setSortStatus}
                    minHeight={200}
                    loading={loading}
                    fetching={loading}
                    paginationText={({ from, to, totalRecords }) =>
                      `Showing  ${from} to ${to} of ${totalRecords} entries`
                    }
                  />
                  {rowData?.length !== undefined && (
                    <CustomPagination
                      total={recordsData}
                      page={page}
                      handleChange={handleChange}
                      dataPerPage={pageSize}
                      handleDataPerPage={handleDataPerPage}
                      PAGE_SIZES={PAGE_SIZES}
                    />
                  )}
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="datatables Advanced-pagination">
                  <DataTable
                    noRecordsText="No results match your search query"
                    highlightOnHover
                    className="whitespace-nowrap table-hover Advanced-pagination"
                    records={rowData}
                    columns={[
                      {
                        // accessor: "prog_name",
                        title: "Name",

                        // sortable: true,
                        render: ({ prog_name }) => <div>{prog_name}</div>,
                      },
                      {
                        // accessor: "prog_gender",
                        title: "Gender",
                        // sortable: true,
                        render: ({ prog_gender }) => <div>{prog_gender}</div>,
                      },
                      {
                        // accessor: "prog_distance_category",
                        title: "Category",
                        // sortable: true,
                        render: ({ prog_distance_category }) => (
                          <div>{prog_distance_category}</div>
                        ),
                      },
                      {
                        // accessor: "prog_distances",
                        title: "Distances",
                        // sortable: true,
                        render: ({ prog_distances }) => (
                          <div>
                            {prog_distances
                              .map((item) => item.segment)
                              .join(", ")}
                          </div>
                        ),
                      },
                      {
                        // accessor: "prog_date",
                        title: "Date",
                        // sortable: true,
                        render: ({ prog_date }) => <div>{prog_date}</div>,
                      },

                      {
                        // accessor: "action",
                        title: "Action",
                        // sortable: true,
                        render: ({ prog_id, event_id, prog_name }) => (
                          <button
                            type="button"
                            className="btn btn-primary flex items-center gap-3 shadow-none"
                            onClick={() =>
                              navigate(`/event/list/athlete/${prog_id}`, {
                                state: { event_id, prog_name, eventName },
                              })
                            }
                          >
                            <IconEye />
                          </button>
                        ),
                      },
                    ]}
                    totalRecords={recordsData}
                    recordsPerPage={pageSize}
                    // page={page}
                    onPageChange={(p) => setPage(p)}
                    recordsPerPageOptions={PAGE_SIZES}
                    onRecordsPerPageChange={setPageSize}
                    sortStatus={sortStatus}
                    onSortStatusChange={setSortStatus}
                    minHeight={200}
                    loading={loading}
                    fetching={loading}
                    paginationText={({ from, to, totalRecords }) =>
                      `Showing  ${from} to ${to} of ${totalRecords} entries`
                    }
                  />
                  {rowData?.length !== undefined && (
                    <CustomPagination
                      total={recordsData}
                      page={page}
                      handleChange={handleChange}
                      dataPerPage={pageSize}
                      handleDataPerPage={handleDataPerPage}
                      PAGE_SIZES={PAGE_SIZES}
                    />
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels> */}
            </Tab.Group>
            {/* </h5> */}
            {/* </div> */}
          </div>
          <div className="datatables Advanced-pagination">
            <DataTable
              noRecordsText="No results match your search query"
              highlightOnHover
              className="whitespace-nowrap table-hover Advanced-pagination"
              records={rowData}
              columns={[
                {
                  // accessor: "athlete_first",
                  title: "First Name",
                  // sortable: true,
                  render: ({ athlete_first }) => <div>{athlete_first}</div>,
                },
                {
                  // accessor: "athlete_last",
                  title: "Last Name",
                  // sortable: true,
                  render: ({ athlete_last }) => <div>{athlete_last}</div>,
                },
                {
                  // accessor: "athlete_yob",
                  title: "YOB",
                  // sortable: true,
                  render: ({ athlete_yob }) => <div>{athlete_yob}</div>,
                },
                {
                  // accessor: "country",
                  title: "Country",
                  // sortable: true,
                  render: ({ athlete_country_name, athlete_flag }) => (
                    <div className="flex items-center gap-2">
                      <img
                        width="24"
                        src={athlete_flag}
                        className="max-w-none"
                        alt="flag"
                      />
                      <div>{athlete_country_name}</div>
                    </div>
                  ),
                },
              ]}
              totalRecords={recordsData}
              recordsPerPage={pageSize}
              // page={page}
              onPageChange={(p) => setPage(p - 1)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              minHeight={200}
              loading={loading}
              fetching={loading}
              paginationText={({ from, to, totalRecords }) =>
                `Showing  ${from} to ${to} of ${totalRecords} entries`
              }
            />
            {rowData?.length !== 0 && (
              <CustomPagination
                total={recordsData}
                page={page}
                handleChange={handleChange}
                dataPerPage={pageSize}
                handleDataPerPage={handleDataPerPage}
                PAGE_SIZES={PAGE_SIZES}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StartListTable;
