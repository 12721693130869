import React, { Fragment } from "react";
import DefaultLayout from "../components/Layouts/DefaultLayout";

const MatxLayout = () => {
  return (
    <Fragment>
      <div className="page">
        <div className="main-content side-content">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <DefaultLayout></DefaultLayout>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MatxLayout;
