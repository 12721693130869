import { DataTable } from "mantine-datatable";
import React, { useState } from "react";
import CustomPagination from "../../../components/Layouts/CustomPagination";
import categoryService from "../../../service/category.service";
import { useEffect } from "react";
import IconRefresh from "../../../components/Icon/IconRefresh";
import Swal from "sweetalert2";

const Category = () => {
  const [rowData, setRowData] = useState([]);
  const [paginationRowData, setPaginationRowData] = useState([]);
  const [page, setPage] = useState(0);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [recordsData, setRecordsData] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(null);

  const initialCheckedState = rowData.map(() => ({
    check: false,
    category_id: null,
  }));
  const [checked, setChecked] = useState(initialCheckedState);
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "id",
    direction: "asc",
  });

  const getCategory = async () => {
    try {
      setLoading(true);
      const response = await categoryService.categoryData();
      const result = response?.data?.data;
      setPaginationRowData(result);
      setRecordsData(response?.data?.total);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("e", e);
    }
  };

  const handleSingleData = (check, index, category_id) => {
    setChecked((prev) => {
      const updatedChecked = [...prev];
      updatedChecked[index] = { check, category_id };
      return updatedChecked;
    });
  };

  const handleSelectAll = (e) => {
    const check = e.target.checked;
    setCheckAll(check);
    setChecked(rowData.map((item) => ({ check, category_id: item.cat_id })));
  };
  const handleRefreshData = async () => {
    try {
      const cat_ids = checked
        .filter((item) => item.check === true)
        .map((item) => item.category_id);
      await categoryService.refreshCategory({ category_id: cat_ids });
      getCategory();
      if (cat_ids.length !== 0) {
        const toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          showCloseButton: true,
          customClass: {
            popup: `color-${"success"}`,
          },
        });
        toast.fire({
          title: "Data Added successfully",
        });
      }
      setCheckAll(null);
      setChecked(initialCheckedState);
    } catch (e) {
      console.log("e", e);
      const toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        customClass: {
          popup: `color-${"danger"}`,
        },
      });
      toast.fire({
        title: "Something went wrong",
      });
    }
  };
  const handleChange = (type, page) => {
    const total = recordsData / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page < newPage - 1) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page > 0) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      setPage(0);
    } else if (type === "Last") {
      setPage(newPage - 1);
    } else {
      setPage(page);
    }
  };
  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  useEffect(() => {
    const from = page * pageSize;
    const to = from + pageSize;
    console.log("frommm", from, to);
    setRowData(paginationRowData && [...paginationRowData.slice(from, to)]);
  }, [page, pageSize, paginationRowData]);
  useEffect(() => {
    setPage(0);
  }, [pageSize]);
  useEffect(() => {
    getCategory();
  }, [page, pageSize]);
  useEffect(() => {
    setChecked(
      rowData.map((item) => ({ check: false, cat_id: item.category_id }))
    );
  }, [rowData]);
  return (
    <>
      <div>
        <div className="panel mt-6 category-table">
          <div className="flex items-center justify-between mb-4">
            <div className="flex gap-5 items-center justify-center">
              <h5 className="font-semibold text-lg dark:text-white-light cursor-pointer">
                Categories
              </h5>
            </div>
            <button
              type="button"
              disabled={checked.length === 0}
              onClick={handleRefreshData}
              className="btn btn-primary flex items-center gap-3 shadow-none"
            >
              <IconRefresh /> Refresh
            </button>
          </div>
          <div className="datatables Advanced-pagination">
            <DataTable
              noRecordsText="No results match your search query"
              highlightOnHover
              className="whitespace-nowrap table-hover Advanced-pagination"
              records={rowData}
              columns={[
                {
                  title: (
                    <div forHtml="checkbox">
                      <label className="flex cursor-pointer items-center">
                        <input
                          type="checkbox"
                          checked={checkAll}
                          onChange={handleSelectAll}
                          className="form-checkbox bg-white dark:bg-black"
                        />
                      </label>
                    </div>
                  ),
                  render: ({ cat_id }, index) => (
                    <div id="checkbox">
                      <label className="flex cursor-pointer items-center">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleSingleData(e.target.checked, index, cat_id)
                          }
                          checked={checked[index]?.check || false}
                          className="form-checkbox bg-white dark:bg-black"
                        />
                      </label>
                    </div>
                  ),
                },
                {
                  // accessor: "cat_name",
                  title: "Name",
                  // sortable: true,
                  render: ({ cat_name, isAdded }) => (
                    <div style={{ color: isAdded === true ? "green" : "red" }}>
                      {cat_name}
                    </div>
                  ),
                },
              ]}
              totalRecords={recordsData}
              recordsPerPage={pageSize}
              // page={page}
              onPageChange={(p) => setPage(p - 1)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              minHeight={200}
              loading={loading}
              fetching={loading}
              paginationText={({ from, to, totalRecords }) =>
                `Showing  ${from} to ${to} of ${totalRecords} entries`
              }
            />
            {rowData?.length !== 0 && (
              <CustomPagination
                total={recordsData}
                page={page}
                handleChange={handleChange}
                dataPerPage={pageSize}
                handleDataPerPage={handleDataPerPage}
                PAGE_SIZES={PAGE_SIZES}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
