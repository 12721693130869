import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../store/themeConfigSlice";
import Dropdown from "../Dropdown";
import IconMenu from "../Icon/IconMenu";
import IconLogout from "../Icon/IconLogout";
import ProfileIcon from "../../assets/images/user-profile.jpeg";
import Logo from "../../assets/images/logo.svg";
import Swal from "sweetalert2";
import HeaderDropdown from "../HeaderDropdown";
import { setLoggedIn, setToken } from "../../store/slice/auth";

const HeaderComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  useEffect(() => {
    const selector = document.querySelector(
      'ul.horizontal-menu a[href="' + window?.location.pathname + '"]'
    );
    if (selector) {
      selector.classList.add("active");
      const all = document.querySelectorAll(
        "ul.horizontal-menu .nav-link.active"
      );
      for (let i = 0; i < all.length; i++) {
        all[0]?.classList.remove("active");
      }
      const ul = selector.closest("ul.sub-menu");
      if (ul) {
        let ele = ul.closest("li.menu").querySelectorAll(".nav-link");
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele?.classList.add("active");
          });
        }
      }
    }
  }, [location]);

  const isRtl = useSelector((state) => state.themeConfig.rtlClass) === "rtl";

  const themeConfig = useSelector((state) => state.themeConfig);
  const dispatch = useDispatch();

  const HandleLogout = () => {
    localStorage.clear();
    const toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      showCloseButton: true,
      customClass: {
        popup: `color-${"success"}`,
      },
    });
    toast.fire({
      title: "Log-out successfully",
    });
    dispatch(setLoggedIn(false));
    dispatch(setToken(null));
    navigate("/login");
  };

  return (
    <header
      className={`z-40 ${
        themeConfig.semidark && themeConfig.menu === "horizontal" ? "dark" : ""
      }`}
    >
      <div className="shadow-sm">
        <div className="relative bg-white flex w-full items-center justify-between px-5 py-2.5 dark:bg-black">
          <button
            type="button"
            className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary lg:flex hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
            onClick={() => dispatch(toggleSidebar())}
          >
            <IconMenu className="w-5 h-5" />
          </button>
          <div className="horizontal-logo flex lg:hidden justify-between items-center ltr:mr-2 rtl:ml-2">
            <Link to="/" className="main-logo flex items-center shrink-0">
              <img
                className="w-8 ltr:-ml-1 rtl:-mr-1 inline"
                src={Logo}
                alt="logo"
              />
            </Link>
            <button
              type="button"
              className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              <IconMenu className="w-5 h-5" />
            </button>
          </div>
          <div>
            <div className="dropdown shrink-0 flex justify-end">
              <HeaderDropdown
                offset={[0, 8]}
                placement={`${isRtl ? "bottom-start" : "bottom-end"}`}
                btnClassName="relative group block"
                button={
                  <div className="w-9 h-9 rounded-full object-cover bg-gray-300 saturate-0 text-center items-center flex justify-center text-2xl group-hover:saturate-100">
                    {userProfile?.user?.charAt(0).toUpperCase()}
                  </div>
                }
              >
                <ul className="text-dark dark:text-white-dark !py-0 w-[230px] font-semibold dark:text-white-light/90">
                  <li>
                    <div className="flex items-center px-4 pb-1">
                      <div className="ltr:pl-4 rtl:pr-4 truncate">
                        <h4 className="text-base">{userProfile?.user}</h4>
                      </div>
                    </div>
                  </li>
                  <li className="border-t border-white-light dark:border-white-light/10">
                    <div
                      onClick={HandleLogout}
                      className="text-danger cursor-pointer flex px-3 py-3"
                    >
                      <IconLogout className="w-4.5 h-4.5 ltr:mr-2 rtl:ml-2 rotate-90 shrink-0" />
                      Sign Out
                    </div>
                  </li>
                </ul>
              </HeaderDropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
