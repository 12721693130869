import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../store/themeConfigSlice";
import { useEffect } from "react";
import IconAward from "../../components/Icon/IconAward";
import IconMenuWidgets from "../../components/Icon/Menu/IconMenuWidgets";
import IconLogout from "../Icon/IconLogout";
import logoImage from "../../assets/images/logo.svg";
import Swal from "sweetalert2";

const Sidebar = () => {
  const themeConfig = useSelector((state) => state.themeConfig);
  const semidark = useSelector((state) => state.themeConfig.semidark);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const selector = document.querySelector(
      '.sidebar ul a[href="' + window?.location.pathname + '"]'
    );
    if (selector) {
      selector.classList.add("active");
      const ul = selector.closest("ul.sub-menu");
      if (ul) {
        let ele = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024 && themeConfig.sidebar) {
      dispatch(toggleSidebar());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const HandleLogout = () => {
    const toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      showCloseButton: true,
      customClass: {
        popup: `color-${"success"}`,
      },
    });
    toast.fire({
      title: "Log-out successfully",
    });
    navigate("/login");
  };
  return (
    <div className={semidark ? "dark" : ""}>
      <nav
        className={`sidebar fixed min-h-screen overflow-hidden h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 ${
          semidark ? "text-white-dark" : ""
        }`}
      >
        <div className="bg-white dark:bg-black h-full">
          <div className="flex justify-between items-center px-4 py-3">
            <NavLink
              to="/"
              className="main-logo flex items-center gap-3 shrink-0"
            >
              <img
                className="w-8 ml-[5px] flex-none"
                src={logoImage}
                alt="logo"
              />
              <span className="text-xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline dark:text-white-light">
                Triathlon Fantasy
              </span>
            </NavLink>
          </div>

          {/* <li className="nav-item">
            <ul>
              <li className="nav-item">
                <NavLink to="/event" className="group">
                  <div className="flex items-center">
                    <IconAward className="group-hover:!text-primary shrink-0" />
                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Events
                    </span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/category" className="group">
                  <div className="flex items-center">
                    <IconMenuWidgets className="group-hover:!text-primary shrink-0" />
                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Category
                    </span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </li> */}
          <PerfectScrollbar className="h-[calc(100vh-60px)] relative">
            <div className="flex flex-col justify-between h-full">
              <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                <li className="nav-item">
                  <ul>
                    <li className="nav-item">
                      <NavLink to="/event" className="group">
                        <div className="flex items-center">
                          <IconAward className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                            Events
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/category" className="group">
                        <div className="flex items-center">
                          <IconMenuWidgets className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                            Category
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </PerfectScrollbar>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
